// DashboardAdmin.tsx
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Space,
  Spin,
  Popconfirm,
  Button,
  Modal,
  Badge,
  Card,
  Tag,
  Col,
  Row,
  Statistic,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CheckSquare,
  Receipt,
  XSquare,
  MailPlus,
  Check,
  X,
} from "lucide-react";
import moment from "moment";
import { toast } from "react-toastify";
import styles from "./dashboard.module.scss";
import {
  IWithdrawal,
  getWithdrawalV2,
  processWithdrawal,
} from "../../services/requests/withdrawal";
import { getAllTransactionsV2 } from "../../services/requests/transactions";
import { ContextApi } from "../../contexts";
import {
  requestRecoverPassEmail,
  sendActivateUserEmail,
} from "../../services/requests/email";
import { updateSimpleUser } from "../../services/requests/users";
import TransactionSteps from "./componentes";
import { setTransaction } from "../../redux/transactions";
import type { MenuProps } from "antd";
import Address from "./componentes/endereco";
import Seller from "./componentes/seller";
import { Products } from "../../components/products/products";
import {
  DollarOutlined,
  ArrowUpOutlined,
  ProductOutlined,
  UserOutlined,
} from "@ant-design/icons";

const DashboardAdmin: React.FC = () => {
  const { getAllUsers, getAllProducts, products, users } =
    useContext(ContextApi);
  const [withdrawals, setWithdrawals] = useState<IWithdrawal[]>([]);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);
  const [currentTransaction, setCurrentTransaction] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    isModalTransactions: false,
    isAddressModalOpen: false,
    isSellerModalOpen: false,
    isProductModalOpen: false,
    isOptionsModalOpen: false,
  });
  const dispatch = useDispatch();
  const transactions = useSelector((state: any) => state.transactions.value);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      getAllUsers();
      getAllProducts();

      const withdrawalResponse = await getWithdrawalV2();
      setWithdrawals(withdrawalResponse.data || []);

      const transactionsResponse = await getAllTransactionsV2();
      const formattedTransactions =
        transactionsResponse.data?.map((t: any, index: number) => ({
          ...t,
          key: index,
        })) || [];
      dispatch(setTransaction(formattedTransactions));
    } catch (error) {
      toast.error("Erro ao obter dados.");
    }
  };

  const processWithdrawalRequest = async (
    id: string,
    payload: IWithdrawal,
    status: string
  ) => {
    setLoading(true);
    try {
      payload.status = status;
      await processWithdrawal(id, payload);
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || "Erro ao processar retirada."
      );
    } finally {
      setLoading(false);
    }
  };

  const actionHandlers: Record<string, () => Promise<void>> = {
    activeEmail: async () => {
      await sendActivateUserEmail(currentUser._id);
      toast.success("E-mail de ativação enviado!");
    },
    passwd: async () => {
      await requestRecoverPassEmail(currentUser.email);
      toast.success("E-mail de recuperação de senha enviado!");
    },
    deactivate: async () => {
      await updateSimpleUser(currentUser._id, {
        active: false,
        activeStatus: "Desativado",
      });
      toast.success("Usuário desativado!");
    },
  };

  const handleMenuClick = async (key: string) => {
    const handler = actionHandlers[key];
    if (handler) {
      try {
        await handler();
      } catch {
        toast.error("Erro ao executar a ação.");
      }
    } else {
      toast.error("Opção inválida!");
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Reenviar e-mail ativação",
      key: "activeEmail",
      icon: <MailPlus />,
    },
    { label: "Enviar o e-mail de troca de senha", key: "passwd" },
    { label: "Desativar usuário", key: "deactivate" },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "Nome",
      key: "userId",
      render: (data) => <span>{data.userId.name}</span>,
    },
    { title: "Valor", dataIndex: "amount", key: "amount" },
    {
      title: "E-mail",
      key: "userId.email",
      render: (data) => <span>{data.userId.email}</span>,
    },
    { title: "Tipo", dataIndex: "method", key: "method" },
    { title: "Chave Pix", dataIndex: "pixKey", key: "pixKey" },
    {
      title: "NIT",
      key: "userId.nit",
      render: (data) => <span>{data.userId.nit || "-"}</span>,
    },
    {
      title: "Nota Fiscal",
      key: "nf",
      render: (data) => (
        <Space size="middle">
          <Receipt
            onClick={() => window.open(data.invoice, "_blank")}
            className={styles.icon}
          />
        </Space>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (data) => (
        <Space size="large">
          <Popconfirm
            title="Aprovar a transferência"
            onConfirm={() =>
              processWithdrawalRequest(data._id, data, "completed")
            }
            okText={<Check />}
            cancelText={<X />}
          >
            <CheckSquare className={styles.icon} />
          </Popconfirm>
          <Popconfirm
            title="Recusar a transferência"
            onConfirm={() =>
              processWithdrawalRequest(data._id, data, "cancelled")
            }
            okText={<Check />}
            cancelText={<X />}
          >
            <XSquare className={styles.icon} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columnsTransactions: ColumnsType<any> = [
    {
      title: "Comprador",
      key: "customer.name",
      render: (data) => <span>{data?.customer?.name}</span>,
    },
    {
      title: "E-mail",
      key: "customer.email",
      render: (data) => <span>{data?.customer?.email}</span>,
    },
    {
      title: "Status",
      key: "status",
      render: (data) =>
        data.status !== "paid" ? (
          data.status === "pending" ? (
            <Tag color="gold">PROCESSANDO</Tag>
          ) : (
            <Tag color="volcano">ENCERRADO</Tag>
          )
        ) : (
          <Tag color="green">PAGO</Tag>
        ),
    },
    {
      title: "Processos",
      key: "status",
      render: (data) => (
        <Button onClick={() => showModal("isModalTransactions", data)}>
          Processos da transação
        </Button>
      ),
    },
    {
      title: "Opções",
      key: "options",
      render: (data) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Tag
            color="#2db7f5"
            style={{ cursor: "pointer", marginBottom: 2 }}
            onClick={() => showModal("isSellerModalOpen", data)}
          >
            Vendedor
          </Tag>
          <Tag
            color="#f50"
            style={{ cursor: "pointer", marginBottom: 2 }}
            onClick={() => showModal("isAddressModalOpen", data)}
          >
            Comprador
          </Tag>
          <Tag
            color="#87d068"
            style={{ cursor: "pointer" }}
            onClick={() => showModal("isProductModalOpen", data)}
          >
            Produto
          </Tag>

          {data?.options?.value && (
            <Tag
              color="#108ee9"
              style={{ cursor: "pointer" }}
              onClick={() => showModal("isOptionsModalOpen", data)}
            >
              Opções de tamanho
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Data",
      key: "createdAt",
      render: (data) => <Tag>{moment(data.createdAt).format("LL")}</Tag>,
    },
    {
      title: "Ações",
      key: "active",
      render: (data) => (
        <Tag
          onClick={() => {
            if (
              transactionCreateUser(data?.items[0]?.code) &&
              data.status === "paid"
            )
              showModal("isModalOpen", data);
          }}
        >
          Ações
        </Tag>
      ),
    },
  ];

  const transactionCreateUser = useCallback(
    (productId: string) => {
      const isCreateUser = products.find(
        (product) => product._id === productId
      );
      return !isCreateUser?.createUser;
    },
    [products]
  );
  const findUser = (id: string) => users.find((u) => u._id === id);
  const findProduct = (id: string) => products.find((p) => p._id === id);
  const showModal = useCallback(
    (modalName: string, data: any = null) => {
      if (data) {
        const customer = users.find(
          (user) =>
            user.email === data.customer.email &&
            user.cpf === data.customer.document
        );
        setCurrentUser(customer);
        setCurrentTransaction(data);
        setCurrentProduct(findProduct(data?.items[0].code));
      }
      setModalState((prevState) => ({
        ...prevState,
        [modalName]: true,
      }));
    },
    [findProduct, findUser, users]
  );

  const handleModalClose = useCallback(() => {
    setModalState({
      isModalOpen: false,
      isModalTransactions: false,
      isAddressModalOpen: false,
      isSellerModalOpen: false,
      isProductModalOpen: false,
      isOptionsModalOpen: false,
    });
  }, []);

  return (
    <section className={styles.dashBoardPage}>
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Statistic
                title="Solicitações de saques."
                value={withdrawals?.length || 0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<DollarOutlined />}
              />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Statistic
                title="Transações totais."
                value={transactions?.length || 0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ProductOutlined />}
              />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Statistic
                title="Usuarios ativos."
                value={users?.length || 0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<UserOutlined />}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <div className={styles.container}>
        {withdrawals.length > 0 && (
          <div>
            <h1>Solicitações de saque:</h1>
            <Table columns={columns} dataSource={withdrawals} />
          </div>
        )}
        <div>
          <h1>Vendas:</h1>
          <Table columns={columnsTransactions} dataSource={transactions} />
        </div>
      </div>

      <Modal
        title={`Ações com o usuario ${currentUser?.name}`}
        open={modalState.isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {items?.map((item: any, index) => (
            <Button key={index} onClick={() => handleMenuClick(item.key)} block>
              {item?.label}
            </Button>
          ))}
        </Space>
      </Modal>

      <Modal
        title="Serviços relacionados aos processos de transação"
        open={modalState.isModalTransactions}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <Badge.Ribbon text="Em fase de testes!" color="green">
          <Card>
            <TransactionSteps transaction={currentTransaction} />
          </Card>
        </Badge.Ribbon>
      </Modal>

      <Modal
        title="Informações do Comprador"
        open={modalState.isAddressModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={900}
      >
        {currentTransaction && (
          <Address currentTransaction={currentTransaction} />
        )}
      </Modal>

      <Modal
        title="Informações do Vendedor"
        open={modalState.isSellerModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={400}
      >
        {currentTransaction && (
          <Seller currentTransaction={currentTransaction} />
        )}
      </Modal>

      <Modal
        title="Informações do Produto"
        open={modalState.isProductModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <Products
          key={currentProduct?._id}
          name={currentProduct?.name}
          value={currentProduct?.price}
          auffs={currentProduct?.auff ?? 0}
          directCommition={currentProduct?.directCommissionValue ?? 0}
          image={currentProduct?.imageUrls[0]}
          link={currentProduct?._id}
        />
      </Modal>

      <Modal
        title="Opções de Tamanho"
        open={modalState.isOptionsModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <div>
          <p>
            {currentTransaction?.options?.value || "Sem opções cadastradas"}
          </p>
        </div>
      </Modal>

      <Spin spinning={loading} fullscreen />
    </section>
  );
};

export default DashboardAdmin;
